
import { useMemo } from 'react';

import useFetch from '../hooks/useFetch';
import useMutation from '../hooks/useMutation';
import {
  CreatePixOrderRequest,
  CreatePixOrderResponse,
  CreateCardOrderRequest,
  CreateCardOrderResponse,
  ReadOrderStatusResponse,
  Order,
} from '../model/order';
import { useAuthContext } from '../state/auth';

const ep = '/orders';

export const useFetchOrders = () => {
  const { auth: { userId } } = useAuthContext();
  const url = useMemo(() => userId ? `/users/${userId}${ep}` : null, [userId]);
  return useFetch<Order[]>(url, undefined, { dedupingInterval: 0 });
}

export const useFetchOrderStatus = (orderId: string) => {
  const url = useMemo(() => orderId ? `${ep}/${orderId}/status` : null, [orderId]);
  return useFetch<ReadOrderStatusResponse>(url, undefined, { dedupingInterval: 0 });
}

export const useCreatePixOrderMutation = () => {
  const { auth: { userId } } = useAuthContext();
  return useMutation<CreatePixOrderRequest, CreatePixOrderResponse>(`/users/${userId}${ep}/pix`, 'post');
}

export const useCreateCreditCardOrderMutation = () => {
  const { auth: { userId } } = useAuthContext();
  return useMutation<CreateCardOrderRequest, CreateCardOrderResponse>(
    `/users/${userId}${ep}/credit`, 'post'
  );
}
