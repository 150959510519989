import useMutation from '../hooks/useMutation';
import { UserLoginRequest, UserLoginResponse } from '../model/auth';

const ep = '/auth';

export const useLoginMutation = () => {
  return useMutation<UserLoginRequest, UserLoginResponse>(ep, 'post');
}

export const useLogoutMutation = () => {
  return useMutation<void, void>(ep, 'delete');
}

export const useForgotPasswordMutation = () => {
  return useMutation<string, void>(`${ep}/password`, 'put');
}
