export enum UserGender {
  male = 'male',
  female = 'female'
}

export enum UserRole {
  admin = 'admin',
  consumer = 'consumer',
}

export interface User {
  id: string
  name: string
  taxId: string
  email: string
  cellphone?: string
  lastName?: string
  bornDate: string
  password: string
  gender?: UserGender
}

export interface UpdateUserRequest {
  name: string
  cellphone?: string
  lastName?: string
  bornDate: string
  gender?: UserGender
}

export interface CreateUserRequest {
  name: string
  lastName?: string
  email: string
  taxId: string
  bornDate: string
  password: string
}

export interface UpdatePasswordRequest {
  password: string
  newPassword: string
}

export interface ReadUserResponse {
  id: string
  name: string
  email: string
  taxId: string
  cellphone?: string
  lastName?: string
  bornDate: string
  password: string
  role: UserRole
  gender?: UserGender
  readonly createdAt: Date
  readonly updatedAt: Date
}