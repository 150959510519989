import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import CopyPaste from '../../component/CopyPaste';
import FixedCta from '../../component/FixedCta';
import Layout from '../../component/Layout';
import useAlert from '../../hooks/useAlert';
import { ScreenRoutes } from '../../router';
import { useCartContext } from '../../state/cart';
import { useOrderContext, useOrderStatusPolling } from '../../state/order';
import { PixAlertStates } from '../../utils/alert';
import { frontExpireTimeFromDate } from '../../utils/order';

import PixIllustration from './PixIllustration';

const PixReview: React.FC = () => {
  const { enqueue } = useAlert();
  const order = useOrderContext();
  const { reset: resetCart } = useCartContext();

  const [timeLeft, setTimeLeft] = React.useState(
    (order.current && frontExpireTimeFromDate(order.current.expirationDate)) ?? 300
  );
  const navigate = useNavigate();

  const pix = useMemo(() => (order.current && order.current.copyPaste) ?? '', [order]);

  const handleCopyPasteClick = useCallback(() => {
    navigator.clipboard.writeText(pix);
    enqueue(PixAlertStates.PIX_COPY_SUCCESS);
  }, [pix, enqueue]);

  const handlePaymentSuccess = useCallback(() => {
    enqueue(PixAlertStates.PIX_PAYMENT_SUCCESS);
    resetCart();
    navigate(ScreenRoutes.ORDERS);
  }, [navigate, resetCart, enqueue]);

  const handlePaymentExpired = useCallback(() => {
    enqueue(PixAlertStates.PIX_TIMEOUT_ERROR);
    navigate(ScreenRoutes.ORDERS);
  }, [navigate, enqueue]);

  useOrderStatusPolling(
    order.current?.orderId,
    order.current?.expirationDate,
    handlePaymentSuccess,
    handlePaymentExpired
  );

  const handleOrdersLinkClick = useCallback(() => {
    navigate(ScreenRoutes.ORDERS);
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft - 1 <= 0) {
        enqueue(PixAlertStates.PIX_TIMEOUT_ERROR);
        navigate(ScreenRoutes.ORDERS);
        return;
      }
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [navigate, timeLeft, enqueue]);

  const progress = useMemo(() => (Math.round(timeLeft) * 100) / 300, [timeLeft]);
  const minutes = useMemo(() => Math.floor(Math.round(timeLeft) / 60), [timeLeft]);
  const seconds = useMemo(() => `0${Math.round(timeLeft) % 60}`.slice(-2), [timeLeft]);

  return (
    <Layout title='Realize o Pagamento com PIX' hiddenTabNavigation>
      <Box sx={style.wait}>
        <PixIllustration />
        <Box sx={style.textContainer}>
          <Typography sx={style.subtitle} variant="subtitle2">
            Pedido aguardando pagamento
          </Typography>
          <Typography sx={style.text} variant="subtitle2">
            Copie o código abaixo e utilize o Pix Copia e Cola no aplicativo que você vai realizar o pagamento.
          </Typography>
          <CopyPaste value={pix} />
          <Typography sx={[style.text, style.textTimingInfo]} variant="subtitle2">
            Você tem até 5 minutos para fazer o pagamento.
          </Typography>
          <Typography sx={style.timeText} variant="subtitle2">
            O tempo para você pagar acaba em:
          </Typography>
          <Typography variant="h5" sx={style.time}>
            {minutes}:{seconds}
          </Typography>
          <LinearProgress sx={style.progress} variant="determinate" value={progress} />
          <Typography variant="subtitle2" sx={style.orderInfo}>
            Você pode acompanhar o status do seu pedido em <Link onClick={handleOrdersLinkClick}>pedidos</Link>.
          </Typography>
          <FixedCta onClick={handleCopyPasteClick}>
            Copiar Código
          </FixedCta>
        </Box>
      </Box>
    </Layout>
  );
};

const style = {
  wait: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
  },
  subtitle: {
    marginTop: '24px',
    fontSize: '16px',
  },
  text: {
    margin: '16px 0 24px 0',
    fontWeight: 'normal',
  },
  orderInfo: {
    width: '80%',
    textAlign: 'center',
    fontWeight: 'normal',
    margin: '24px auto',
  },
  textTimingInfo: {
    marginTop: '24px',
  },
  timeText: {
    textAlign: 'left',
    fontWeight: 300,
    color: grey[600],
  },
  time: {
    fontWeight: 700,
    textAlign: 'left',
    marginBottom: '8px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
    padding: '0 16px',
    margin: 'auto',
  },
  progress: {
    height: 10,
    borderRadius: 5,
  }
};

export default React.memo(PixReview);
