import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useForgotPasswordMutation, useLoginMutation, useLogoutMutation } from '../api/auth';
import { useRegisterMutation } from '../api/users';
import useAlert from '../hooks/useAlert';
import { UserLoginRequest } from '../model/auth';
import { CreateUserRequest } from '../model/user';
import { AuthAlertStates } from '../utils/alert';

import { persist, persistKeys } from './persistor';

interface Auth {
  userId: string;
  token: string;
  isLoggedIn: boolean;
}

const defaultAuthState: AuthContextType = {
  auth: {
    userId: '',
    token: '',
    isLoggedIn: false,
  },
  setAuth: () => { },
  unauthorize: () => { },
};

interface AuthContextType {
  auth: Partial<Auth>;
  setAuth: (auth: Auth) => void;
  unauthorize: () => void;
}

type AuthPersist = Partial<Auth>;

export const AuthContext = createContext<AuthContextType>(defaultAuthState);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [auth, setAuth] = useState<Partial<Auth>>(defaultAuthState.auth);

  useEffect(() => {
    const persistedAuth: AuthPersist = JSON.parse(localStorage.getItem(persistKeys.auth) || '{}');
    setAuth(persistedAuth || defaultAuthState.auth);
  }, []);

  const setAuthAndPersist = useCallback((newAuth: Partial<Auth>) => {
    setAuth(newAuth);
    persist<AuthPersist>(persistKeys.auth, newAuth);
  }, [setAuth]);

  const unauthorize = useCallback(() => {
    setAuthAndPersist({ ...defaultAuthState.auth });
  }, [setAuthAndPersist]);

  return (
    <AuthContext.Provider value={{ auth, setAuth: setAuthAndPersist, unauthorize }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext<AuthContextType>(AuthContext);
  if (!context) {
    throw new Error(
      'useAuthContext must be used within an AuthContextProvider'
    );
  }
  return context;
};

export const useLogout = () => {
  const { trigger } = useLogoutMutation();
  const { unauthorize } = useAuthContext();

  const logout = useCallback(async () => {
    await trigger();
    unauthorize();
  }, [trigger, unauthorize]);

  return {
    logout
  };
}

interface UseForgotPasswordProps {
  onSuccess: () => void;
}

export const useForgotPassword = (props?: UseForgotPasswordProps) => {
  const { isLoading, trigger } = useForgotPasswordMutation();
  const { enqueue } = useAlert();

  const forgotPassword = useCallback(async (email: string) => {
    try {
      await trigger(email);
      enqueue(AuthAlertStates.FORGOT_PASSWORD_SUCCESS);
      props?.onSuccess();
    } catch (error) {
      enqueue(AuthAlertStates.FORGOT_PASSWORD_ERROR);
    }
  }, [trigger, enqueue, props]);

  return {
    forgotPassword,
    isLoading,
  };
}

interface UseLoginProps {
  onSuccess: () => void;
}

export const useLogin = (props?: UseLoginProps) => {
  const { trigger, isLoading } = useLoginMutation();
  const { setAuth } = useAuthContext();
  const { enqueue } = useAlert();

  const login = useCallback(async (data: UserLoginRequest) => {
    try {
      const { token, userId } = await trigger(data);
      setAuth({ token, userId, isLoggedIn: true });
      props?.onSuccess();
    } catch (error) {
      enqueue(AuthAlertStates.LOGIN_ERROR);
    }
  }, [trigger, setAuth, enqueue, props]);

  return {
    login,
    isLoading,
  };
}

interface UseRegisterProps {
  onSuccess: () => void;
}

export const useRegister = (props?: UseRegisterProps) => {
  const { trigger, isLoading } = useRegisterMutation();
  const { enqueue } = useAlert();

  const register = useCallback(async (data: CreateUserRequest) => {
    try {
      await trigger(data);
      enqueue(AuthAlertStates.REGISTER_SUCCESS);
      props?.onSuccess();
    } catch (error) {
      enqueue(AuthAlertStates.REGISTER_ERROR);
    }
  }, [trigger, enqueue, props]);

  return {
    register,
    isLoading,
  };
}