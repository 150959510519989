export const lockerDevices = {
  poc: {
    '01': '41e9c21f-0552-4535-b7c5-9ac7c3fa36fd',
  },
};


export const posDeviceMap = {
  // imperial suítes
  '64c496901c9c61c51475466e': 'poc-093124-0001-01',
}