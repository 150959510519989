import CancelIcon from '@mui/icons-material/Cancel';
import { Box, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDeleteWalletCardMutation, useFetchWalletCards } from '../../api/wallet';
import Layout from '../../component/Layout';
import WalletCard from '../../component/WalletCard';
import useAlert from '../../hooks/useAlert';
import { IWalletCard } from '../../model/wallet';
import { ScreenRoutes } from '../../router';
import { globalStyles, theme } from '../../theme';
import { CardAlertStates } from '../../utils/alert';

const Wallet: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueue } = useAlert();
  const { trigger, isLoading } = useDeleteWalletCardMutation(location.state?.card?.id);
  const { mutate } = useFetchWalletCards();

  const card = useMemo<IWalletCard | undefined>(() => {
    return location.state?.card;
  }, [location.state?.card]);

  useEffect(() => {
    if (!card) {
      navigate(ScreenRoutes.HOME);
    }
  }, [card, navigate]);

  const handleCardDelete = useCallback(async () => {
    try {
      await trigger();
      await mutate();
      enqueue(CardAlertStates.REMOVE_SUCCESS);
      navigate(ScreenRoutes.PROFILE);
    } catch (error) {
      enqueue(CardAlertStates.REMOVE_ERROR);
    }
  }, [trigger, mutate, enqueue, navigate]);

  if (!card) {
    return null;
  }

  return (
    <>
      <Layout title='Carteira' hiddenTabNavigation>
        <Box position={'relative'} marginTop='32px'>
          <WalletCard card={card} />
          {isLoading ? <CircularProgress
            sx={{
              position: 'absolute',
              top: `calc(50% - ${globalStyles.circularProgress.size / 2}px)`,
              left: `calc(50% - ${globalStyles.circularProgress.size / 2}px)`,
            }}
            size={globalStyles.circularProgress.size}
          /> : null}
        </Box>
        <List>
          <ListItem disablePadding>
            <ListItemButton disabled={isLoading}>
              <ListItemIcon sx={style.remove}>
                <CancelIcon />
              </ListItemIcon>
              <ListItemText
                onClick={handleCardDelete}
                sx={style.remove}
                primary="Remover forma de pagamento"
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Layout>
    </>
  );
};

const style = {
  remove: {
    color: theme.palette.error.main,
  },
}

export default React.memo(Wallet);
