import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import React from 'react';

const ActionPlaceholder = React.memo(styled.div`height: 52px;`);

interface FixedCtaProps {
  disabled?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const FixedCta: React.FC<FixedCtaProps> = ({ disabled, onClick, children }) => {
  return (
    <>
      <ActionPlaceholder />
      <Button
        fullWidth
        sx={style.action}
        variant="contained"
        onClick={onClick}
        disableElevation
        size='large'
        disabled={disabled}
      >
        {children}
      </Button>
    </>
  );
};

const style = {
  action: {
    borderRadius: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '52px',
    zIndex: 1,
  },
}

export default React.memo(FixedCta);

