import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';

function TextField(props: TextFieldProps): JSX.Element {
  return (
    <MuiTextField
      margin='normal'
      variant='standard'
      fullWidth
      size='small'
      {...props}
    />
  );
}

export default React.memo(TextField);
