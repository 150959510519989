import styled from '@emotion/styled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo } from 'react';

import Layout from '../../component/Layout';
import TextField from '../../component/TextField';
import useAlert from '../../hooks/useAlert';
import { PaymentType } from '../../model/order';
import { IWalletCard } from '../../model/wallet';
import { useCartContext, useCartItemsCount, useCartValue } from '../../state/cart';
import { useCouponContext } from '../../state/coupon';
import { useCreateCreditCardOrder, useCreatePixOrder } from '../../state/order';
import { usePosContext } from '../../state/pos';
import { globalStyles } from '../../theme';
import { CardAlertStates, PosAlertStates } from '../../utils/alert';
import { centsToReal, priceToBrl } from '../../utils/conversion';
import { PAGSEGURO_PUBLIC_KEY } from '../../utils/pagseguro';
// import { successPagSeguroEncryptedTestCard } from '../../utils/wallet';

import CardReview from './CardReview';
import CouponsSection from './CouponsSection';
import OrderReviewSection from './OrderReviewSection';
import OrderReviewValues, { OrderReviewValuesProps } from './OrderReviewValues';
import PaymentMethodSection from './PaymentMethodSection';

const PixActionPlaceholder = React.memo(styled.div`height: 120px;`);

const OrderReview: React.FC = () => {
  const cartItemsCount = useCartItemsCount();
  const cartValue = useCartValue();
  const cart = useCartContext();
  const pos = usePosContext();
  const coupon = useCouponContext();
  const { enqueue } = useAlert();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(PaymentType.PIX);
  const { create: createPixOrder } = useCreatePixOrder();
  const { create: createCreditCardOrder } = useCreateCreditCardOrder();
  const [selectedCard, setSelectedCard] = React.useState<IWalletCard>();
  const [cvv, setCvv] = React.useState<{ value: string, shown: boolean }>(
    { value: '', shown: false }
  );

  const values = useMemo<OrderReviewValuesProps[]>(() => [
    { title: 'Quantidade de Produtos', value: cartItemsCount.toString() },
    { title: 'Subtotal', value: priceToBrl(cartValue) },
    { title: 'Desconto', value: coupon.selected ? `R$ ${centsToReal(coupon.selected.value)}` : 'R$ 0,00' },
    { title: 'Total', value: priceToBrl(cartValue - ((coupon.selected?.value ?? 0) / 100)), bold: true },
  ], [cartItemsCount, cartValue, coupon.selected]);

  const handlePixPayment = useCallback(async () => {
    if (!pos.selected) {
      return enqueue(PosAlertStates.POS_SELECTION_NEEDED_ERROR);
    }

    await createPixOrder(Object.values(cart.items))
  }, [cart, createPixOrder, enqueue, pos.selected]);

  const handleCardPayment = useCallback(async () => {
    if (!selectedCard) {
      return enqueue(CardAlertStates.NOT_SELECTED_ERROR);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const card = (window as any).PagSeguro.encryptCard({
      publicKey: PAGSEGURO_PUBLIC_KEY,
      holder: selectedCard.holder,
      number: selectedCard.number.replace(/\D/g, ''),
      expMonth: selectedCard.expMonth,
      expYear: selectedCard.expYear,
      securityCode: cvv.value,
    });

    // const encrypted = successPagSeguroEncryptedTestCard;
    const encrypted = card.encryptedCard;
    const hasErrors = card.hasErrors;
    const errors = card.errors;

    if (hasErrors) {
      console.error(errors);
      return enqueue(CardAlertStates.ENCRYPT_ERROR);
    }

    createCreditCardOrder(Object.values(cart.items), encrypted, selectedCard);
  }, [cart, createCreditCardOrder, cvv.value, enqueue, selectedCard]);

  const disabledPayment = useMemo(() => {
    return selectedPaymentMethod === PaymentType.CREDIT_CARD &&
      (!selectedCard || !((cvv.value ?? '').length > 2))
  }, [selectedCard, selectedPaymentMethod, cvv.value]);

  const handleCvvChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    setCvv({ ...cvv, value });
  }, [cvv]);

  const toggleCvvVisibility = useCallback(() => {
    setCvv({ ...cvv, shown: !cvv.shown });
  }, [cvv]);

  return (
    <>
      <Layout title='Revise Seu Pedido' hiddenTabNavigation>
        <Box sx={style.container}>
          <OrderReviewSection title='Cupons de Desconto'>
            <CouponsSection />
          </OrderReviewSection>
        </Box>
        <Box sx={style.container}>
          <OrderReviewSection title='Método de Pagamento'>
            <PaymentMethodSection setSelectedPaymentMethod={setSelectedPaymentMethod} />
          </OrderReviewSection>
        </Box>
        {selectedPaymentMethod === PaymentType.CREDIT_CARD ? <Box sx={style.container}>
          <Typography sx={style.title} variant='h6'>Escolha o cartão</Typography>
          <CardReview
            onCardSelect={setSelectedCard}
            selectedCard={selectedCard}
          />
          {selectedCard ? <TextField
            label={`Código de segurança (final ${selectedCard.number.slice(-4)})`}
            type={cvv.shown ? 'text' : 'password'}
            value={cvv.value}
            name='password'
            onChange={handleCvvChange}
            helperText={'Nós nunca salvamos o código de segurança do seu cartão'}
            InputProps={{
              endAdornment: (
                <IconButton sx={globalStyles.passwordIcon} size="small" onClick={toggleCvvVisibility} edge="end">
                  {cvv.shown ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          /> : null}
        </Box> : null}
        <Box sx={style.section}>
          <Typography sx={style.title} variant='h6'>
            Resumo de Valores
          </Typography>
          {values.map((value) => (
            <OrderReviewValues key={value.title} {...value} />
          ))}
        </Box>
      </Layout>
      <PixActionPlaceholder />
      <Card sx={style.action}>
        <CardActionArea
          disabled={disabledPayment}
          onClick={() => {
            selectedPaymentMethod === PaymentType.PIX ? handlePixPayment() : handleCardPayment()
          }}
          sx={style.actionArea}
        >
          <Typography sx={style.actionText}>
            {selectedPaymentMethod === PaymentType.PIX ? 'Gerar QR Code' : 'Pagar com Cartão'}
          </Typography>
        </CardActionArea>
      </Card>
    </>
  );
};

const style = {
  container: {
    margin: '32px 0',
    fontWeight: '400',
  },
  section: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '14px',
  },
  addCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '4px 2px',
    margin: '4px 0 0 0',
    textAlign: 'left',
    height: '64px',
    backgroundColor: 'primary.main',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    '> h6': {
      padding: '0 24px 0 16px',
      color: 'custom.white',
    }
  },
  addCardIcon: {
    fontSize: '40px',
    transform: 'rotate(-8deg)',
    margin: '0 8px',
    color: 'custom.white',
  },
  action: {
    borderRadius: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '120px',
    zIndex: 1,
    backgroundColor: 'primary.main',
  },
  actionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  actionIcon: {
    fontSize: '52px',
    color: 'custom.white',
  },
  actionText: {
    fontFamily: '\'Roboto Condensed\', sans-serif',
    fontSize: '26px',
    fontWeight: 'bold',
    color: 'custom.white',
    padding: '0 16px',
    textTransform: 'uppercase',
    textAlign: 'center'
  }
};

export default React.memo(OrderReview);
