import MuiBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import * as React from 'react';

import { useLoadingContext } from '../state/loading';

function Backdrop() {
  const { loading } = useLoadingContext();

  return (
    <MuiBackdrop
      sx={style.backdrop}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </MuiBackdrop>
  );
}

const style = {
  backdrop: {
    color: 'custom.white',
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  }
}

export default React.memo(Backdrop);
