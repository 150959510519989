import React from 'react';
import { IMaskInput } from 'react-imask';

interface CardNumberMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CardNumberMask = React.forwardRef<HTMLInputElement, CardNumberMaskProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="0000 0000 0000 0000"
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value: string) => onChange({ target: { name: props.name, value } })}
        overwrite
        inputMode='numeric'
        onChange={event => event.preventDefault()} />
    );
  },
);

export default React.memo(CardNumberMask);