import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

import { IWalletCard } from '../model/wallet';
import { cardNumberFormat } from '../utils/wallet';

interface WalletCardProps {
  card: IWalletCard;
  minimalist?: boolean;
  onClick?: () => void;
}

const WalletCard: React.FC<WalletCardProps> = ({ card, minimalist, onClick }) => (
  ((minimalist === true) ?
    <Box
      sx={{ ...style.card, boxShadow: 3 }}
      onClick={onClick}
      display='flex'
      flexDirection='row'
      justifyContent={'space-between'}
    >
      <Box flexDirection={'column'}>
        <Box display='flex' flexDirection='column' gap='5px'>
          <Typography sx={style.value}>
            {card.holder}
          </Typography>
        </Box>
        <Typography sx={{ outline: 'none', fontSize: '15px', fontWeight: 'bold' }}>
          {cardNumberFormat(card.number)}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='row' justifyContent={'space-between'}>
        <Box display='flex' flexDirection='column'>
          <Typography sx={style.label} variant='caption'>
            CPF
          </Typography>
          <Typography sx={style.value}>
            {card.taxId}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column'>
          <Typography sx={style.label} variant='caption'>Validade</Typography>
          <Typography sx={style.value}>
            {card.expMonth.toString().padStart(2, '0')}/{card.expYear}
          </Typography>
        </Box>
      </Box>
    </Box> : <Box
      sx={{ ...style.card, boxShadow: 3 }}
      onClick={onClick}
      display='flex'
      flexDirection='column'
      gap='15px'
    >
      <Box display='flex' flexDirection='row'>
        <Box display='flex' flexDirection='column' gap='5px'>
          <Typography sx={style.value}>
            {card.holder}
          </Typography>
        </Box>
      </Box>
      <Typography sx={{ outline: 'none', fontSize: '15px', fontWeight: 'bold' }}>
        {cardNumberFormat(card.number)}
      </Typography>
      <Box display='flex' flexDirection='row' justifyContent={'space-between'}>
        <Box display='flex' flexDirection='column'>
          <Typography sx={style.label} variant='caption'>
            CPF
          </Typography>
          <Typography sx={style.value}>
            {card.taxId}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column'>
          <Typography sx={style.label} variant='caption'>Validade</Typography>
          <Typography sx={style.value}>
            {card.expMonth.toString().padStart(2, '0')}/{card.expYear}
          </Typography>
        </Box>
      </Box>
    </Box>)
);

const style = {
  card: {
    width: '100%',
    backgroundColor: '#f2f2f2',
    borderRadius: '10px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '16px',
    background: 'linear-gradient(to right, #dcdcdc, #f0f0f0)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  label: {
    fontFamily: 'monospace',
  },
  value: {
    fontFamily: 'monospace',
    fontWeight: 'bold',
    fontSize: '15px',
    textTransform: 'uppercase',
  },
};

export default React.memo(WalletCard);

