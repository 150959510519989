import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';

import Layout from '../../component/Layout';
import { useOrderContext, useRefreshOrders } from '../../state/order';
import { globalStyles } from '../../theme';
import { isExpiredOrder, isPaidOrder, isWaitingOrder, mostRecent } from '../../utils/order';

import { OrderExpiredCard, OrderPaidCard, OrderWaitCard } from './OrderCard';
import OrderCardSkeleton from './OrderCardSkeleton';

const Orders = () => {
  const order = useOrderContext();

  const { isLoading } = useRefreshOrders();

  // sort by createdAt most news first
  const waitingOrders = useMemo(() => order.list.items.filter(isWaitingOrder).sort(mostRecent), [order.list.items]);
  const expiredOrders = useMemo(() => order.list.items.filter(isExpiredOrder).sort(mostRecent), [order.list.items]);
  const paidOrders = useMemo(() => order.list.items.filter(isPaidOrder).sort(mostRecent), [order.list.items]);

  return (
    <Layout title="Meus Pedidos" hiddenBackButton>
      {isLoading && Array.from({ length: 3 }).map((_i, idx) => <OrderCardSkeleton key={idx} />)}
      {!isLoading && (
        <>
          {waitingOrders.length > 0 && <Box sx={style.container}>
            {waitingOrders.map(item => <OrderWaitCard key={item.id} order={item} />)}
          </Box>}
          {paidOrders.length > 0 && <Box sx={style.container}>
            <Typography variant="h6" sx={globalStyles.sectionTitle}>Confirmados</Typography>
            {paidOrders.map(item => <OrderPaidCard key={item.id} order={item} />)}
          </Box>}
          {expiredOrders.length > 0 && <Box sx={style.container}>
            <Typography variant="h6" sx={globalStyles.sectionTitle}>Expiraram</Typography>
            {expiredOrders.map(item => <OrderExpiredCard key={item.id} order={item} />)}
          </Box>}
        </>
      )}
    </Layout>
  );
};

const style = {
  container: {
    margin: '24px 0 16px 0',
  }
};

export default React.memo(Orders);
