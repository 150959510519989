import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';

import AuthLayout from '../../component/AuthLayout';
import TextField from '../../component/TextField';
import { AuthScreenMode } from '../../model/screen';
import { useForgotPassword } from '../../state/auth';
import syntaxValidator from '../../utils/validator';

interface ForgotPasswordProps {
  setAuthScreen: (screen: AuthScreenMode) => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ setAuthScreen }) => {
  const [email, setEmail] = useState({ value: '', error: false });
  const { forgotPassword, isLoading } = useForgotPassword({
    onSuccess: () => { setAuthScreen('login') },
  });

  const handleForgotPassword = useCallback(async () => {
    if (!syntaxValidator.email(email.value)) {
      return setEmail((prevEmail) => ({ ...prevEmail, error: true }));
    }

    await forgotPassword(email.value);
  }, [email.value, forgotPassword]);

  const handleRegister = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAuthScreen('register');
  }, [setAuthScreen]);

  const handleLogin = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAuthScreen('login');
  }, [setAuthScreen]);

  const handleEmailUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail((prevEmail) => ({ ...prevEmail, value, error: false }));
  }, []);

  const handleCancel = useCallback(() => setAuthScreen(undefined), [setAuthScreen]);

  return (
    <AuthLayout
      onAction={handleForgotPassword}
      title='Esqueceu a senha?'
      subtitle='Lembrou a senha?'
      subtitleAction='Volte para o Login'
      onSubtitleAction={handleLogin}
      actionText='Enviar'
      loading={isLoading}
      onCancel={handleCancel}
    >
      <TextField
        label="Email"
        type="email"
        value={email.value}
        onChange={handleEmailUpdate}
        error={email.error}
        helperText={email.error ? 'E-mail inválido' : ''}
      />
      <Typography sx={style.forgotPassword} variant="caption">
        Não está registrado?
        <Link sx={style.signin} onClick={handleRegister}>
          Registre-se
        </Link>
      </Typography>
    </AuthLayout>
  );
};

const style = {
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '4px',
  },
  signin: {
    marginLeft: '4px',
  }
}

export default React.memo(ForgotPassword);
