import { Alert, Button, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../component/Layout';
import SmartCarousel from '../../component/SmartCarousel';
import { ScreenRoutes } from '../../router';
import { useAuthContext } from '../../state/auth';
import { useOpenLocker } from '../../state/locker';
import { useUserContext } from '../../state/user';
import { AlertMessages, LockerAlertStates } from '../../utils/alert';
import { lockerDevices } from '../../utils/locker-devices';
import { checkAdultAge } from '../../utils/user';

const COUNTDOWN_TO_REDIRECT = 300000;

const Locker: React.FC = () => {
  const { user } = useUserContext();
  const { auth } = useAuthContext();
  const { openLocker, isOpen, isLoading, error } = useOpenLocker();
  const { id } = useParams();
  const [lockerError, setLockerError] = useState<LockerAlertStates>();

  const adultAge = useMemo(() => checkAdultAge(user.bornDate), [user.bornDate]);

  const lockerId = useMemo(() => {
    const key = (id ?? '').split('-')[0] as keyof typeof lockerDevices;
    const device = (id ?? '').split('-').pop() as keyof typeof lockerDevices[keyof typeof lockerDevices];
    return lockerDevices[key][device];
  }, [id]);

  useEffect(() => {
    if (!lockerId) {
      return;
    }
    // if (!auth.isLoggedIn) {
    //   return setLockerError(LockerAlertStates.LOCKER_NOT_LOGGED_IN_ERROR);
    // }
    // if (!adultAge) {
    //   return setLockerError(LockerAlertStates.LOCKER_NOT_ADULT_ERROR);
    // }
    if (auth.isLoggedIn && adultAge) {
      openLocker(lockerId);
    }
  }, [adultAge, auth.isLoggedIn, lockerId, openLocker]);

  useEffect(() => {
    if (error && !lockerError) {
      setLockerError(LockerAlertStates.LOCKER_OPEN_ERROR);
    }
  }, [error, lockerError]);

  return (
    <Layout title='Liberação de Travas' hiddenTabNavigation>
      {
        isLoading ? null :
          isOpen ? <LockReleased /> :
            // eslint-disable-next-line max-len
            !auth.isLoggedIn ? <LockerUnauthorized setLockerError={setLockerError} openLocker={() => openLocker(lockerId)} /> :
              lockerError ? <LockerLocked error={lockerError} /> :
                null
      }
      <Container sx={{ marginTop: 2 }}>
        <SmartCarousel />
      </Container>
    </Layout>
  );
};

const LockerUnauthorized = ({ setLockerError, openLocker }:
  { setLockerError: React.Dispatch<React.SetStateAction<LockerAlertStates | undefined>>, openLocker: () => void }) => {
  // button to confirm age then openLocker
  return (
    <Box sx={style.wait}>
      <Box sx={style.textContainer}>
        <Typography sx={style.subtitle} variant="subtitle2">
          Para liberar a trava, por favor confirme maioridade.
        </Typography>
      </Box>
      <Container sx={style.buttonContainer}>
        <Button
          sx={style.button}
          variant="contained"
          color="primary"
          onClick={openLocker}
        >
          Sou maior de 18 anos
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => setLockerError(LockerAlertStates.LOCKER_NOT_ADULT_ERROR)}
        >
          Cancelar
        </Button>
      </Container>

    </Box>
  );
}

const LockerLocked = ({ error }: { error?: LockerAlertStates }) => {
  const [timer, setTimer] = useState<number>(COUNTDOWN_TO_REDIRECT);
  useEffect(() => {
    if (timer === 0) {
      return;
    }
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const navigate = useNavigate();

  const handleRedirect = useCallback(() => {
    navigate(ScreenRoutes.HOME);
  }, [navigate]);

  useEffect(() => {
    if (timer === 0) {
      handleRedirect();
    }
  }, [handleRedirect, timer]);

  return (
    <Box sx={style.wait}>
      <Box sx={style.textContainer}>
        <Typography sx={style.subtitle} variant="subtitle2">
          Erro ao liberar a trava, tente novamente.{' '}
          Você será redirecionado em {timer / 1000} segundos, caso prefira clique para{' '}
          <Link onClick={handleRedirect}>
            continuar sua compra
          </Link>
        </Typography>
      </Box>
      {error ? <Alert sx={style.alert} severity='error'>
        {AlertMessages[error]}
      </Alert> : null}
    </Box>
  );
}

const LockReleased = () => {
  const navigate = useNavigate();

  const handleRedirect = useCallback(() => {
    navigate(ScreenRoutes.HOME);
  }, [navigate]);

  return (
    <Box sx={style.wait}>
      <Box sx={style.textContainer}>
        <Typography sx={style.subtitle} variant="subtitle2">
          Trava liberada com sucesso.{' '}
          <Link onClick={handleRedirect}>
            Continuar sua compra
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

const style = {
  wait: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
  },
  subtitle: {
    marginTop: '24px',
    fontSize: '16px',
  },
  text: {
    margin: '16px 0 24px 0',
    fontWeight: 'normal',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
    padding: '0 16px',
    margin: 'auto',
  },
  alert: {
    marginTop: '12px',
    width: '100%',
  },
  button: {
    width: '100%',
    marginRight: '16px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '16px 0 32px 0',
  }
};

export default React.memo(Locker);
