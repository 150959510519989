import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';

import { AMLabsProduct } from '../model/product';
import { priceToBrl } from '../utils/conversion';
import { productfy } from '../utils/naming';

interface CartProductCardPriceProps {
  price: number;
  specialPrice?: number;
}

const CartProductCardPrice: React.FC<CartProductCardPriceProps>
  = React.memo(({ price, specialPrice }: CartProductCardPriceProps) => {
    if (!specialPrice) {
      return <Typography sx={style.price} variant="h6" color='#000'>{priceToBrl(price)}</Typography>
    }

    return (
      <Box sx={style.priceContainer}>
        <Typography sx={[style.price, style.specialPrice]} variant="h6">{priceToBrl(specialPrice)}</Typography>
        <Typography sx={[style.price, style.offPrice]} color='#000' variant="h6">{priceToBrl(price)}</Typography>
      </Box>
    );
  });

interface CartProductCardProps {
  product: AMLabsProduct;
  quantity: number;
  onRemoveClick: (product: AMLabsProduct) => void;
  onAddClick: (product: AMLabsProduct) => void;
}

const CartProductCard: React.FC<CartProductCardProps> = ({
  product, quantity, onRemoveClick, onAddClick
}) => {
  return (
    <Box key={product.id} sx={style.card}>
      <CardMedia
        component="img"
        src={`/assets/products/${product.images?.at(0) ?? '300x200.jpg'}`}
        alt={product.description}
        sx={style.media}
      />
      <CardContent sx={style.content}>
        <Typography variant="subtitle2" sx={style.description}>
          {productfy(product.description, 40)}
        </Typography>
        <CartProductCardPrice price={product.defaultPrice} specialPrice={product.specialPrice} />
      </CardContent>
      <CardActions sx={style.actions}>
        <IconButton onClick={() => onRemoveClick(product)}>
          {quantity === 1 ?
            <DeleteIcon color='primary' /> :
            <IndeterminateCheckBoxIcon color='primary' />}
        </IconButton>
        <Typography sx={style.quantity} variant="subtitle1" >{quantity}</Typography>
        <IconButton sx={style.addButton} onClick={() => onAddClick(product)}>
          <AddBoxIcon color='primary' />
        </IconButton>
      </CardActions>
    </Box>
  );
}

const style = {
  cardsContainer: {
    marginTop: '16px',
  },
  card: {
    display: 'flex',
    marginBottom: '16px',
    borderBottom: `1px solid ${grey[400]}`,
    '> img': {
      padding: '4px 8px',
      height: '80px',
      objectFit: 'contain',
      marginRight: '8px',
      marginBottom: '4px',
      borderRadius: '4px',
      background: 'custom.white',
    }
  },
  media: {
    width: '80px',
  },
  content: {
    height: '80px',
    flexGrow: 1,
    padding: '8px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  description: {
    wordBreak: 'break-all',
  },
  actions: {
    paddingRight: 0,
  },
  addButton: {
    marginLeft: '0px !important',
  },
  price: {
    fontSize: '14px',
  },
  specialPrice: {
    color: green[600],
  },
  offPrice: {
    fontSize: '14px',
    textDecoration: 'line-through',
    marginLeft: '8px',
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  quantity: {
    width: '22px',
    textAlign: 'center',
  },
};

export default React.memo(CartProductCard);
