export enum DataStoreEvent {
  VIEW = 'VIEW',
  CLICK = 'CLICK',
  SLIDE = 'SLIDE',
}

export interface DataStore {
  event: DataStoreEvent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}