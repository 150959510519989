import { Paper, Box } from '@mui/material';
import { useCallback, useEffect, memo } from 'react';
import Carousel from 'react-material-ui-carousel';

import { useDataStore } from '../api/datastore';
import { Banner, banners } from '../model/banner';
import { DataStoreEvent } from '../model/datastore';

const settings = {
  autoPlay: true,
  animation: 'fade' as const,
  indicators: true,
  duration: 500,
  navButtonsAlwaysVisible: false,
  navButtonsAlwaysInvisible: false,
  cycleNavigation: true,
  fullHeightHover: true,
  swipe: true,
};

const SmartCarousel = () => {
  return (
    <Carousel className="SecondExample" {...settings}>
      {banners.map((item, index) => (
        <Project banner={item} key={index} />
      ))}
    </Carousel>
  );
};

interface ProjectProps {
  banner: Banner;
}

const Project = memo(({ banner: item }: ProjectProps) => {
  const { trigger } = useDataStore();

  useEffect(() => {
    if (!item) return;
    console.log('item viewe', item);
    trigger({ event: DataStoreEvent.VIEW, data: item });
  }, [item]);

  const handleClick = useCallback(() => {
    if (!item) return;
    console.log('clicked', item);
    trigger({ event: DataStoreEvent.CLICK, data: item });
  }, [item]);

  return (
    <Paper
      className="Project"
      elevation={10}
      sx={{
        cursor: 'pointer',
        height: '275px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 0,
      }}
      onClick={() => {
        handleClick();
      }}
    >
      <Box
        component="img"
        alignSelf={'center'}
        sx={{
          maxHeight: '100%',  
          width: { xs: '100%', sm: '100%', md: '70%' },  
          objectFit: 'fill',
        }}
        src={item.image}
        alt={item.title}
        onClick={() => {
          window.open(item.href, '_blank');
        }}
      />
    </Paper>
  );
});

export default memo(SmartCarousel);
