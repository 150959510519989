import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import useAlert from '../hooks/useAlert';
import { PixAlertStates } from '../utils/alert';

interface CopyPasteProps {
  value: string;
}

const CopyPaste: React.FC<CopyPasteProps> = ({ value }) => {
  const { enqueue } = useAlert();

  const handleCopyPasteClick = React.useCallback(() => {
    navigator.clipboard.writeText(value);
    enqueue(PixAlertStates.PIX_COPY_SUCCESS);
  }, [value, enqueue]);

  return (
    <Box onClick={handleCopyPasteClick} sx={style.container}>
      <Box sx={style.text}>
        <Typography component="span" variant="body2">
          {value}
        </Typography>
      </Box>
      <ContentCopyIcon color='primary' />
    </Box>
  );
}

const style = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 6px',
    width: '100%',
    height: '36px',
    backgroundColor: grey[300],
    borderRadius: '4px',
    overflow: 'none',
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingRight: '2px',
    ' span': {
      fontSize: '16px'
    }
  },
}

export default React.memo(CopyPaste);
