import { useMemo } from 'react';

import useFetch from '../hooks/useFetch';
import useMutation from '../hooks/useMutation';
import { IWalletCard, WalletCardCreate } from '../model/wallet';
import { useAuthContext } from '../state/auth';

const ep = '/wallet-cards';

export const useCreateWalletCardMutation = () => {
  const { auth: { userId } } = useAuthContext();
  return useMutation<WalletCardCreate, string>(ep, 'post', { params: { userId } });
}

export const useFetchWalletCards = () => {
  const { auth: { userId } } = useAuthContext();
  // userId is needed to fetch the wallet cards
  const url = useMemo(() => userId ? ep : null, [userId]);
  return useFetch<IWalletCard[]>(url, { userId });
}

export const useDeleteWalletCardMutation = (walletCardId: string) => {
  const { auth: { userId } } = useAuthContext();
  return useMutation<string, void>(`${ep}/${walletCardId}`, 'delete', { params: { userId } });
}
