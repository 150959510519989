import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';

import AuthLayout from '../../component/AuthLayout';
import TextField from '../../component/TextField';
import { AuthScreenMode } from '../../model/screen';
import { useLogin } from '../../state/auth';
import { globalStyles } from '../../theme';
import syntaxValidator from '../../utils/validator';

export interface LoginFormData {
  password: { value: string; error: boolean };
  email: { value: string; error: boolean };
}

interface LoginDrawerProps {
  setAuthScreen: React.Dispatch<React.SetStateAction<AuthScreenMode>>;
}

const Login: React.FC<LoginDrawerProps> = ({ setAuthScreen }) => {
  const [formData, setFormData] = useState<LoginFormData>({
    password: { value: '', error: false },
    email: { value: '', error: false },
  });
  const [showPassword, setShowPassword] = useState(false);
  const { login, isLoading } = useLogin({
    onSuccess: () => setAuthScreen(undefined),
  });

  const handleErrorChange = useCallback((key: keyof LoginFormData, error: boolean) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: { ...prevFormData[key], error },
    }));
  }, [setFormData]);

  const handleLogin = useCallback(async () => {
    let valid = true;
    if (!syntaxValidator.email(formData.email.value)) {
      handleErrorChange('email', true);
      valid = false;
    }
    if (!syntaxValidator.nonempty(formData.password.value)) {
      handleErrorChange('password', true);
      valid = false;
    }

    if (!valid) {
      return;
    }

    await login({
      email: formData.email.value,
      password: formData.password.value,
    });
  }, [formData.email.value, formData.password.value, login, handleErrorChange]);

  const handleRegister = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAuthScreen('register');
  }, [setAuthScreen]);

  const handleForgotPassword = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAuthScreen('forgot-password');
  }, [setAuthScreen]);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(show => !show);
  }, [setShowPassword]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: { value, error: false },
    }));
  };

  const handleCancel = useCallback(() => setAuthScreen(undefined), [setAuthScreen]);

  return (
    <AuthLayout
      onAction={handleLogin}
      title='Entrar'
      subtitle='Não está cadastrado?'
      subtitleAction='Cadastre-se'
      onSubtitleAction={handleRegister}
      actionText='Entrar'
      loading={isLoading}
      onCancel={handleCancel}
    >
      <TextField
        label='Email'
        type='email'
        name='email'
        value={formData.email.value}
        onChange={handleValueChange}
        error={formData.email.error}
        helperText={formData.email.error ? 'E-mail inválido' : ''}
      />
      <TextField
        label="Senha"
        type={showPassword ? 'text' : 'password'}
        value={formData.password.value}
        name='password'
        onChange={handleValueChange}
        error={formData.password.error}
        helperText={formData.password.error ? 'Senha não pode ser vazia' : ''}
        InputProps={{
          endAdornment: (
            <IconButton sx={globalStyles.passwordIcon} size="small" onClick={togglePasswordVisibility} edge="end">
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          ),
        }}
      />
      <Typography sx={style.forgotPassword} variant="caption">
        <Link onClick={handleForgotPassword}>
          Esqueceu a senha?
        </Link>
      </Typography>
    </AuthLayout>
  );
};

const style = {
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '4px',
  }
}

export default React.memo(Login);
