import { useMemo } from 'react';

import useFetch from '../hooks/useFetch';
import { PopulatedCoupon } from '../model/coupon';
import { useAuthContext } from '../state/auth';

const ep = '/coupons';

export const useFetchCoupons = () => {
  const { auth: { userId } } = useAuthContext();
  const url = useMemo(() => userId ? `users/${userId}${ep}` : null, [userId]);

  return useFetch<PopulatedCoupon[]>(url, undefined, { dedupingInterval: 0 });
}
