import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HearingIcon from '@mui/icons-material/Hearing';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import grey from '@mui/material/colors/grey';
import Container from '@mui/material/Container';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScreenRoutes } from '../../router';
import { useAuthContext, useLogout } from '../../state/auth';
import { EventHub, MappedEvents } from '../../utils/event';

interface Item {
  title: string;
  subtitle: string;
  icon: JSX.Element;
  action: () => void;
}

const MenuList: React.FC = () => {
  const navigate = useNavigate();
  const { auth } = useAuthContext();
  const { logout } = useLogout();

  const handleContactClick = useCallback(() => {
    navigate(ScreenRoutes.INFORMATION);
  }, [navigate]);

  const handleStoreClick = useCallback(() => {
    navigate(ScreenRoutes.STORE);
  }, [navigate]);

  const handleLoginClick = useCallback(() => {
    EventHub.emit(MappedEvents.LoginAttempt);
  }, []);

  const items = useMemo<Item[]>(() => [
    {
      title: 'Loja',
      subtitle: 'Compre online e retire no local',
      icon: <StoreIcon color='primary' />,
      action: handleStoreClick,
    },
    {
      title: 'Centro de Informações',
      subtitle: 'Entre em contato com o suporte',
      icon: <HearingIcon color='primary' />,
      action: handleContactClick,
    },
    auth.isLoggedIn ?
      {
        title: 'Sair',
        subtitle: 'Desconecte-se da sua conta',
        icon: <MeetingRoomIcon color='primary' />,
        action: logout,
      } :
      {
        title: 'Entrar',
        subtitle: 'Aproveite nossas ofertas',
        icon: <PersonIcon color='primary' />,
        action: handleLoginClick,
      },
  ], [auth.isLoggedIn, handleContactClick, handleLoginClick, logout, handleStoreClick]);

  return (
    <Card elevation={3} >
      {items.map((item, idx) => (
        <CardActionArea
          sx={style.cardActionArea(idx !== items.length - 1)}
          key={item.title}
          onClick={item.action}
        >
          <CardHeader
            avatar={
              <Container sx={style.iconContainer}>
                {item.icon}
              </Container>
            }
            titleTypographyProps={{ variant: 'body2', fontWeight: 600 }}
            subheaderTypographyProps={{ variant: 'body2', color: 'grey.600' }}
            title={item.title}
            subheader={item.subtitle}
            action={<ChevronRightIcon sx={style.actionIcon} />}
            sx={style.cardHeader}
          />
        </CardActionArea>
      ))}
    </Card>
  );
};

const style = {
  cardActionArea: (bordered: boolean) => ({
    borderRadius: 0,
    borderBottom: bordered ? `1px solid ${grey[400]}` : 'none',
  }),
  iconContainer: {
    backgroundColor: 'custom.primary.lighter',
    borderRadius: '50%',
    width: '44px',
    height: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionIcon: {
    color: 'grey.600',
  },
  cardHeader: {
    padding: '24px 12px 24px 24px',
  }
}

export default React.memo(MenuList);
