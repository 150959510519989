import { useCallback } from 'react';

import { useCreateContactMessageMutation } from '../api/contact';
import useAlert from '../hooks/useAlert';
import { InformationAlertStates } from '../utils/alert';

import { usePosContext } from './pos';
import { useUserContext } from './user';

export const useCreateContactMessage = () => {
  const { enqueue } = useAlert();
  const { user } = useUserContext();
  const pos = usePosContext();
  const { trigger, isLoading } = useCreateContactMessageMutation();

  const send = useCallback(async (message: string) => {
    try {
      await trigger({
        userId: user.id,
        userName: user.name,
        userLastName: user.lastName,
        userEmail: user.email,
        userCellphone: user.cellphone,
        posId: pos.selected?.id.toString() ?? '',
        posName: pos.selected?.name ?? '',
        value: message
      });
      enqueue(InformationAlertStates.MESSAGE_SUCCESS);
    } catch {
      enqueue(InformationAlertStates.MESSAGE_ERROR);
    }
  }, [enqueue, pos.selected, trigger, user.cellphone, user.email, user.id, user.lastName, user.name]);

  return { isLoading, send };
};
