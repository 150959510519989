import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';

import TextField from '../../component/TextField';
import { useUpdatePassword } from '../../state/user';
import { globalStyles } from '../../theme';
import syntaxValidator from '../../utils/validator';

interface PasswordInformationFormData {
  password: { value: string; error: boolean };
  newPassword: { value: string; error: boolean };
  confirmNewPassword: { value: string; error: boolean };
}

const PasswordInformation: React.FC = () => {
  const [formData, setFormData] = useState<PasswordInformationFormData>({
    password: { value: '', error: false },
    newPassword: { value: '', error: false },
    confirmNewPassword: { value: '', error: false },
  });
  const { isLoading, update: updatePassword } = useUpdatePassword();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: { value, error: false },
    }));
  };

  const handleErrorChange = useCallback((key: keyof PasswordInformationFormData, error: boolean) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: { ...prevFormData[key], error },
    }));
  }, [setFormData]);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(show => !show);
  }, [setShowPassword]);

  const toggleNewPasswordVisibility = useCallback(() => {
    setShowNewPassword(show => !show);
  }, [setShowNewPassword]);

  const toggleConfirmNewPasswordVisibility = useCallback(() => {
    setShowConfirmNewPassword(show => !show);
  }, [setShowConfirmNewPassword]);

  const handleUpdatePassword = useCallback(async () => {
    let valid = true;

    if (!syntaxValidator.password(formData.password.value)) {
      handleErrorChange('password', true);
      valid = false;
    }
    if (!syntaxValidator.password(formData.newPassword.value)) {
      handleErrorChange('newPassword', true);
      valid = false;
    }
    if (formData.newPassword.value !== formData.confirmNewPassword.value) {
      handleErrorChange('confirmNewPassword', true);
      valid = false;
    }

    if (!valid) {
      return;
    }

    await updatePassword({
      password: formData.password.value,
      newPassword: formData.newPassword.value,
    });
  }, [formData, handleErrorChange, updatePassword]);

  return (
    <>
      <Typography variant="h6" sx={globalStyles.sectionTitle}>
        Alterar Senha
      </Typography>
      <TextField
        label="Senha"
        type={showPassword ? 'text' : 'password'}
        value={formData.password.value}
        name='password'
        onChange={handleValueChange}
        error={formData.password.error}
        helperText={formData.password.error ?
          'Senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula e 1 número' :
          ''
        }
        InputProps={{
          endAdornment: (
            <IconButton sx={globalStyles.passwordIcon} size="small" onClick={togglePasswordVisibility} edge="end">
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          ),
        }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Nova senha"
        type={showPassword ? 'text' : 'password'}
        value={formData.newPassword.value}
        onChange={handleValueChange}
        name='newPassword'
        error={formData.newPassword.error}
        helperText={formData.newPassword.error ?
          'Nova senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula e 1 número' :
          ''
        }
        InputProps={{
          endAdornment: (
            <IconButton
              sx={globalStyles.passwordIcon}
              size="small"
              onClick={toggleNewPasswordVisibility}
              edge="end"
            >
              {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          ),
        }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Confirmar nova senha"
        type={showPassword ? 'text' : 'password'}
        value={formData.confirmNewPassword.value}
        onChange={handleValueChange}
        name='confirmNewPassword'
        error={formData.confirmNewPassword.error}
        helperText={formData.confirmNewPassword.error ? 'Senhas não coincidem' : ''}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={globalStyles.passwordIcon}
              size="small"
              onClick={toggleConfirmNewPasswordVisibility}
              edge="end"
            >
              {showConfirmNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          ),
        }}
        InputLabelProps={{ shrink: true }}
      />
      <Button
        variant="contained"
        fullWidth
        disableElevation
        sx={[globalStyles.radiusButton, style.button]}
        onClick={handleUpdatePassword}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={globalStyles.circularProgress.size} /> : 'Atualizar Senha'}
      </Button>
    </>
  );
};

const style = {
  button: {
    marginTop: '16px',
  },
}

export default React.memo(PasswordInformation);
