import CreditCardIcon from '@mui/icons-material/CreditCard';
import PixIcon from '@mui/icons-material/Pix';
import React from 'react';

import HorizontalRadio from '../../component/HorizontalRadioProps';
import { PaymentType } from '../../model/order';

interface PaymentMethodSectionProps {
  setSelectedPaymentMethod: (method: PaymentType) => void;
}

const PaymentMethodSection: React.FC<PaymentMethodSectionProps> = ({ setSelectedPaymentMethod }) => {

  const [selectedRadio, setSelectedRadio] = React.useState(PaymentType.PIX);

  const handleRadioClick = (method: PaymentType) => {
    setSelectedPaymentMethod(method);
    setSelectedRadio(method);
  };

  return <>
    <HorizontalRadio
      image={PixIcon}
      title='Pix'
      checked={selectedRadio === PaymentType.PIX}
      onClick={() => handleRadioClick(PaymentType.PIX)}
    ></HorizontalRadio>
    <HorizontalRadio
      image={CreditCardIcon}
      title='Cartão de Crédito'
      checked={selectedRadio === PaymentType.CREDIT_CARD}
      onClick={() => handleRadioClick(PaymentType.CREDIT_CARD)}
    ></HorizontalRadio>
  </>
};

export default React.memo(PaymentMethodSection);
