import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import FeaturedProductCard from '../../component/FeaturedProductCard';
import FixedCta from '../../component/FixedCta';
import Layout from '../../component/Layout';
import { ScreenRoutes } from '../../router';
import { useCartItemsCount } from '../../state/cart';
import { usePosContext } from '../../state/pos';
import { useUserContext } from '../../state/user';
import { checkAdultAge } from '../../utils/user';

const Store: React.FC = () => {
  const navigate = useNavigate();
  const pos = usePosContext();
  const { user } = useUserContext();
  const cartItemsCount = useCartItemsCount();
  const adultAge = checkAdultAge(user.bornDate);

  const uniqueCategories = Array.from(
    new Set(pos.inventory.map((product) => product.productCategoryName))
  ).filter((category) => adultAge || category !== 'BEBIDA ÁLCOOLICA');

  const handleCategoryClick = useCallback((category: string) => {
    const element = document.getElementById(category);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleActionClick = useCallback(() => {
    navigate(ScreenRoutes.CART);
  }, [navigate]);

  return (
    <>
      <Layout title='Loja' hiddenTabNavigation>
        <Container sx={style.horizontalContainer}>
          {uniqueCategories.map((category) => (
            <Button
              sx={style.categoryTag}
              key={category}
              onClick={() => handleCategoryClick(category)}
              variant="outlined"
            >
              {category}
            </Button>
          ))}
        </Container>
        <Box sx={style.items}>
          {uniqueCategories.map((category) => (
            <Box sx={style.category} id={category} key={category}>
              <Typography variant="h6" sx={style.categoryTitle}>
                {category.toUpperCase()}
              </Typography>
              <Box sx={style.horizontalContainer}>
                {pos.inventory
                  .filter((product) => product.productCategoryName === category)
                  .map((product) => (
                    <FeaturedProductCard key={product.id} product={product} />
                  ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Layout>
      {cartItemsCount > 0 &&
        <FixedCta disabled={cartItemsCount === 0} onClick={handleActionClick}>
          Verificar Carrinho
        </FixedCta>}
    </>
  );
};

const style = {
  categoryTag: {
    marginTop: '12px',
    height: '32px',
    minWidth: 'max-content',
    ':not(:last-child)': {
      marginRight: '16px',
    },
  },
  category: {
    marginTop: '16px',
  },
  categoryTitle: {
    marginBottom: '8px',
  },
  horizontalContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    padding: '0 0 2px 0',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  items: {
    paddingTop: '8px',
    marginBottom: '32px',
  }
};

export default React.memo(Store);
