import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import green from '@mui/material/colors/green';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo } from 'react';

import { AMLabsProduct } from '../model/product';
import { useAddCartItem } from '../state/cart';
import { priceToBrl } from '../utils/conversion';
import { productfy } from '../utils/naming';

interface FeaturedProductCardProps {
  product?: AMLabsProduct;
}

interface FeaturedProductCardFilledProps {
  product: AMLabsProduct;
}

const FeatureProductCardSkeleton: React.FC = React.memo(() => {
  return (
    <Card elevation={0} sx={style.card}>
      <Skeleton width={148} height={148} animation='wave' variant="rectangular" />
      <Skeleton
        width={148}
        height={21}
        animation='wave'
        variant="rectangular"
        sx={style.cardContentSkeleton}
      />
    </Card>
  );
});

interface FeaturedProductCardPriceProps {
  price: number;
  specialPrice?: number;
}

const FeaturedProductCardPrice: React.FC<FeaturedProductCardPriceProps>
  = React.memo(({ price, specialPrice }: FeaturedProductCardPriceProps) => {
    if (!specialPrice) {
      return <Typography sx={style.cardPrice} variant="h6">{priceToBrl(price)}</Typography>
    }

    return (
      <Box sx={style.priceContainer}>
        <Typography sx={[style.cardPrice, style.specialPrice]} variant="h6">{priceToBrl(specialPrice)}</Typography>
        <Typography sx={[style.cardPrice, style.offPrice]} variant="h6">{priceToBrl(price)}</Typography>
      </Box>
    );
  });

const FeaturedProductCardFilled: React.FC<FeaturedProductCardFilledProps> =
  React.memo(({ product }: FeaturedProductCardFilledProps) => {
    const addCartItem = useAddCartItem({ showSuccess: true });

    const handleClick = useCallback(() => {
      addCartItem(product);
    }, [addCartItem, product]);

    const imageUrl = useMemo(() => `/assets/products/${product.images?.at(0) ?? '300x200.jpg'}`, [product.images]);

    return (
      <Card elevation={3} sx={style.card}>
        <CardMedia component="img" image={imageUrl} title={product.description} />
        <CardContent sx={style.cardContent}>
          <Typography
            sx={style.description}
            variant="subtitle2"
          >
            {productfy(product.description)}
          </Typography>
          <CardActions sx={style.cardActions}>
            <FeaturedProductCardPrice price={product.defaultPrice} specialPrice={product.specialPrice} />
            <IconButton onClick={handleClick} color="primary" aria-label="add to shopping cart">
              <AddShoppingCartIcon />
            </IconButton>
          </CardActions>
        </CardContent>
      </Card>
    );
  });

const FeaturedProductCard: React.FC<FeaturedProductCardProps> = ({ product }) => {
  if (!product) {
    return <FeatureProductCardSkeleton />;
  }

  return <FeaturedProductCardFilled product={product} />;
}

const style = {
  card: {
    height: '100%',
    width: '148px',
    flex: '0 0 148px',
    ':not(:last-child)': {
      marginRight: '16px',
    },
    '> img': {
      height: '148px',
      objectFit: 'contain',
      padding: '8px 2px 0 2px',
    }
  },
  description: {
    height: '44px',
    wordBreak: 'break-all',
  },
  cardActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '8px 0 0 0',
  },
  cardPrice: {
    fontWeight: 600,
    fontSize: '16px',
    paddingBottom: '4px',
    color: 'grey.700'
  },
  specialPrice: {
    color: green[600],
  },
  offPrice: {
    fontSize: '12px',
    textDecoration: 'line-through',
    position: 'absolute',
    bottom: -14,
    left: 1,
  },
  priceContainer: {
    position: 'relative',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    padding: '16px 8px',
    paddingBottom: '16px !important',
  },
  cardContentSkeleton: {
    marginTop: '16px',
  }
};

export default React.memo(FeaturedProductCard);
