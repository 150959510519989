import { createBrowserRouter, Navigate } from 'react-router-dom';

import ErrorBoundary from './component/ErrorBoundary';
import Cart from './domain/cart';
import Home from './domain/home';
import Information from './domain/information';
import Locker from './domain/locker';
import OrderReview from './domain/order-review';
import Orders from './domain/orders';
import PixReview from './domain/pix-review';
import Profile from './domain/profile';
import Store from './domain/store';
import Wallet from './domain/wallet';
import { useAuthContext } from './state/auth';
import { EventHub, MappedEvents } from './utils/event';

export enum ScreenRoutes {
  HOME = '/',
  PROFILE = '/profile',
  INFORMATION = '/information',
  CART = '/cart',
  STORE = '/store',
  ORDER_REVIEW = '/order/review',
  PIX_REVIEW = '/order/review/pix',
  CARD_REVIEW = '/order/review/card',
  ORDERS = '/orders',
  WALLET = '/wallet',
  QR_LOCKER = '/qr-locker/:id',
  // Pass the delta you want to go in the history stack.
  // For example, navigate(-1) is equivalent to hitting the back button.
  GO_BACK = -1,
}

const protectedRoutes: ScreenRoutes[] = [
  ScreenRoutes.PROFILE,
  ScreenRoutes.ORDER_REVIEW,
  ScreenRoutes.PIX_REVIEW,
  ScreenRoutes.ORDERS,
  ScreenRoutes.WALLET,
];

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { auth: { isLoggedIn } } = useAuthContext();

  if (!isLoggedIn) {
    EventHub.emit(MappedEvents.LoginAttempt);
    return <Navigate to={ScreenRoutes.HOME as string} />;
  }

  return children;
};

export const router = createBrowserRouter([
  {
    path: ScreenRoutes.HOME as string,
    element: <Home />,
  },
  {
    path: ScreenRoutes.PROFILE as string,
    element: <Profile />,
  },
  {
    path: ScreenRoutes.INFORMATION as string,
    element: <Information />,
  },
  {
    path: ScreenRoutes.CART as string,
    element: <Cart />,
  },
  {
    path: ScreenRoutes.STORE as string,
    element: <Store />,
  },
  {
    path: ScreenRoutes.ORDER_REVIEW as string,
    element: <OrderReview />,
  },
  {
    path: ScreenRoutes.PIX_REVIEW as string,
    element: <PixReview />,
  },
  {
    path: ScreenRoutes.ORDERS as string,
    element: <Orders />,
  },
  {
    path: ScreenRoutes.WALLET as string,
    element: <Wallet />,
  },
  {
    path: ScreenRoutes.QR_LOCKER as string,
    element: <Locker />,
  }
].map(route => ({
  ...route,
  element: protectedRoutes.includes(route.path as ScreenRoutes) ? (
    <ProtectedRoute>{route.element}</ProtectedRoute>
  ) : route.element,
  // Error element is necessary due non-friendly react router error boundary
  errorElement: <ErrorBoundary />
})));
