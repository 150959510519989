import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import Badge from '@mui/material/Badge';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import grey from '@mui/material/colors/grey';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScreenRoutes } from '../router';
import { useOrderContext } from '../state/order';
import { isWaitingOrder } from '../utils/order';

interface Tab { [key: string]: TabValue }

interface TabValue {
  id: number;
  route: ScreenRoutes;
}

const TabNavigation: React.FC = () => {
  const tabs = useMemo<Tab>(() => ({
    home: { id: 0, route: ScreenRoutes.HOME },
    orders: { id: 1, route: ScreenRoutes.ORDERS },
    profile: { id: 2, route: ScreenRoutes.PROFILE },
  }), []);

  const navigate = useNavigate();

  const initialTab = useMemo<TabValue>(
    () => Object.values(tabs).find(tab => tab.route === window.location.pathname) || tabs.home,
    [tabs]
  );

  const [tab] = useState<TabValue>(initialTab);
  // swr will avoid multiple requests, context is not necessary (to show orders badge number)
  const { list } = useOrderContext();

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, id: number) => {
      const newTab = Object.values(tabs).find(item => item.id === id) || tabs.home;
      if (tab.id === newTab.id) {
        return;
      }

      navigate(newTab.route as string);
    },
    [navigate, tab.id, tabs]
  );

  const waitingOrders = useMemo(
    () => list.items?.reduce((acc, cur) => acc + (isWaitingOrder(cur) ? 1 : 0), 0),
    [list.items]
  );

  return (
    <BottomNavigation
      value={tab.id}
      onChange={handleChange}
      sx={style.container}
      showLabels
    >
      <BottomNavigationAction
        label={'INÍCIO'}
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
        label={'PEDIDOS'}
        icon={
          <Badge badgeContent={waitingOrders} color="error">
            <AssignmentIcon />
          </Badge>
        }
      />
      <BottomNavigationAction
        label={'PERFIL'}
        icon={<PersonIcon />}
      />
    </BottomNavigation>
  );
};

const style = {
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `1px solid ${grey[400]}`,
    paddingBottom: '7px',
    height: '72px',
    zIndex: 1,
    backgroundColor: grey[200],
  }
}

export default React.memo(TabNavigation);
