import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';

import { AlertMessages, AlertType } from '../utils/alert';

type AlertVariant = 'info' | 'default' | 'error' | 'success' | 'warning';

const useAlert = () => {
  const enqueue = useCallback((type: AlertType | string, variant?: AlertVariant) => {
    const v = variant ?? type.split('_').pop()?.toLowerCase() as AlertVariant | undefined ?? 'info';
    const m = AlertMessages[type as AlertType] ?? type;
    enqueueSnackbar(m, { variant: v });
  }, []);

  return {
    enqueue,
  }
}

export default useAlert;
