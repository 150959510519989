
import { useMemo } from 'react';

import useFetch from '../hooks/useFetch';
import useMutation from '../hooks/useMutation';
import { CreateUserRequest, ReadUserResponse, UpdatePasswordRequest, UpdateUserRequest, User } from '../model/user';
import { useAuthContext } from '../state/auth';

const ep = '/users';

export const useRegisterMutation = () => {
  return useMutation<CreateUserRequest>(ep, 'post');
}

export const useFetchUser = () => {
  const { auth: { userId } } = useAuthContext();
  const url = useMemo(() => userId ? `${ep}/${userId}` : null, [userId]);
  return useFetch<ReadUserResponse>(url);
}

export const useUpdateUserMutation = () => {
  const { auth: { userId } } = useAuthContext();
  return useMutation<UpdateUserRequest, User>(`${ep}/${userId}`, 'put');
}

export const useUpdatePasswordMutation = () => {
  const { auth: { userId } } = useAuthContext();
  return useMutation<UpdatePasswordRequest>(`${ep}/${userId}/password`, 'put');
}
