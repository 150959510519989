import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';

import FeaturedProductCard from '../../component/FeaturedProductCard';
import { AMLabsProduct } from '../../model/product';
import { usePosContext } from '../../state/pos';
import { useUserContext } from '../../state/user';
import { globalStyles } from '../../theme';
import { checkAdultAge } from '../../utils/user'

// productId, ranked by index
const featuredProductsRanking = [
  199,  // HEINEKEN LONG NECK
  201,  // COCA COLA LATA 350ML
  123,  // KIT KAT NESTLÉ
  200,  // COCA COLA 2 LITROS
  163,  // DORITOS
  235,  // COCA ZERO LATA 350ML
  1982, // CHEETOS PARMESÃO
  727,  // RUFFLES ORIGINAL ELMA CHIPS
  3187, // COCA COLA ZERO 1L
  895,  // BUDWEISER 350ML
  112,  // BISCOITEO RECHEADO PASSA TEMPO
  430,  // H2OH Limoneto Zero Açúcar 500ml
  364,  // SENSAÇÕES PREMIUM CHIPS FRANGO GRELHADO 45g
  1850, // GUARANÁ ANTARCTICA 2 LITROS
  2080, // CHOCOLATE BIS XTRA OREO
  211,  // CREME DE AVELÃ NUTELLA 140G
  991,  // MONSTER ENERGY
  893,  // SKOL BEATS SENSES 269ML
];

/**
 *  Consider 8 products at most
 *  - quantity should be > 0 (products should be available)
 *  - avoid "BEBIDA ÁLCOOLICA" for under 18
 *  - use 'featuredProducts' ranking
 */
const Featured: React.FC = () => {
  const pos = usePosContext();
  const { user } = useUserContext();

  const featuredProducts = useMemo<(AMLabsProduct | undefined)[]>(() => {
    if (pos.inventory.length === 0) {
      return Array.from({ length: 8 }).map(() => undefined);
    }

    const adultAge = checkAdultAge(user.bornDate);

    const validProducts = pos.inventory.filter(({ productId, adultOnly, currentQuantity }) => {
      const isAvailable = currentQuantity > 0;
      const isFeatured = featuredProductsRanking.includes(productId);

      return isAvailable && (!adultOnly || adultAge) && isFeatured;
    });

    const sortedProducts = validProducts.sort((a, b) => {
      const aIndex = featuredProductsRanking.indexOf(a.productId);
      const bIndex = featuredProductsRanking.indexOf(b.productId);

      return aIndex - bIndex;
    });

    return sortedProducts.slice(0, 8);
  }, [pos.inventory, user.bornDate]);

  return (
    <>
      <Typography variant="h6" sx={globalStyles.sectionTitle}>
        Adicione agora mesmo
      </Typography>
      <Container sx={style.productsContainer}>
        {featuredProducts.map((product, idx) => (
          <FeaturedProductCard key={product ? product.id : idx} product={product} />
        ))}
      </Container>
    </>
  );
}

const style = {
  productsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    padding: '0 0 2px 0',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }
};

export default React.memo(Featured);
