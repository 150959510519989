export const cardNumberFormat = (input: string): string => {
  // Format as credit card number with spaces every 4 digits
  const length = input.length;
  const maskedCreditCard = '**** **** **** ' + input.slice(length - 4, length);
  return maskedCreditCard;
};

// eslint-disable-next-line max-len
export const successPagSeguroEncryptedTestCard = 'RdYtbkUWgoXX3wIYiKHqIuFXOr4ZVMeTyJEKpRghXyjFBv87htodcKBgUQ1+6lGC+6BgI0JOBlPT99epLqMzwpTM3qEwCpg1PLnv14GrZM1+V2PIql6UK4OxXbiEcLTSa//P2aeFKX7hTbQD2inWAS2c4S14Q7dAFEvauKkU/8FUKnqs5Zb/0KsciSDgDBLpdFXhya6vpMw9UrEWx+k4+fbKf4lIs6qrgbbyPDyqiljqN1Gfen7Pbw9uBxS+RnDuFcwcluEvfjE3vmmVS0VqGYp63tru1+V89vAGlLIYQ7SAlWWfuXpMUseb8zQAnZ75mWBS2My/fsJSmi2V0q+7sg==';

// eslint-disable-next-line max-len
export const failedPagSeguroEncryptedTestCard = 'FKVzRKHtwyPif2xhO9S7CluWtVSApNCOTPnll3BGuLbZqqtssQpt2FL8fMYt7wV9QYLbqnK9Zl2BJk9BXfGHYe/WomlNfP6d9rQVKUyLEK5E23cZJ7lUy2IHyS6hZFFRrVn3SlevulEUw9UeDmqQM+BgS0pzpcuoSVKH/10tnCW38rYtrh0D7bQUP3Yt+5bODoL9EbUhCWpuVgJ9SRnsPd4p8mXWarMuQeFzlZljDzyy765TIE6VNXYTQU0efZzlkjC5Yf9s1VNuii8UWd0Y9UXk0K5eFT2QS0QwW+SmnfXp4gXX4HHGFBYu54wYL6wN59QvBCSY+jEbR1FJ/GoywA==';