import doceAcaiBanner from '../assets/banners/doce-acai-banner.jpg';
import eddieBurguerBanner from '../assets/banners/eddie-burguer.jpeg';

export interface Banner {
  title: string;
  image: string;
  href: string;
}

export const banners: Banner[] = [
  {
    title: 'Doce Açaí',
    image: doceAcaiBanner,
    href: 'https://pedido.anota.ai/loja/doce-acaijp?f=ms',
  },
  {
    title: 'Eddie Burguer',
    image: eddieBurguerBanner,
    href: 'https://eddie.menudino.com/',
  },
];
