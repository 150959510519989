import { useCallback, useState, createContext, useEffect, useContext } from 'react';

import { useFetchCoupons } from '../api/coupons';
import useAlert from '../hooks/useAlert';
import { PopulatedCoupon } from '../model/coupon';
import { CouponAlertStates } from '../utils/alert';
import { realToCents } from '../utils/conversion';
import { calculateDiscount } from '../utils/coupon';

import { useCartValue } from './cart';
import { persist, persistKeys } from './persistor';

interface CouponContextType extends CouponData {
  select: (coupon?: PopulatedCoupon) => void;
  setList: (list: PopulatedCoupon[]) => void;
}

export const defaultCouponState: CouponContextType = {
  list: {
    items: [],
  },
  select: () => { },
  setList: () => { },
}

interface CouponData {
  list: {
    items: PopulatedCoupon[];
  },
  selected?: {
    id: string;
    value: number;
  },
}

type CouponPersist = Partial<CouponData>;

export const CouponContext = createContext<CouponContextType>(defaultCouponState);

export const CouponProvider = ({ children }: { children: React.ReactNode }) => {
  const [list, setList] = useState<CouponData['list']>(defaultCouponState.list);
  const [selected, setSelected] = useState<CouponData['selected']>(defaultCouponState.selected);
  const cartValue = useCartValue();

  useEffect(() => {
    const persistedCoupon: CouponPersist = JSON.parse(localStorage.getItem(persistKeys.coupon) || '{}');
    setList(persistedCoupon.list || defaultCouponState.list)
    setSelected(persistedCoupon.selected || defaultCouponState.selected);
  }, []);

  const setListAndPersist = useCallback((coupons: PopulatedCoupon[]) => {
    const newList = { items: coupons };
    setList(newList);
    persist<CouponPersist>(persistKeys.coupon, { list: newList });
  }, [setList]);

  const select = useCallback((coupon?: PopulatedCoupon) => {
    const amount = realToCents(cartValue);
    const value = coupon ? calculateDiscount(coupon, amount) : 0;
    const newSelected = coupon ? { id: coupon.id, value } : undefined;
    setSelected(newSelected);
    persist<CouponPersist>(persistKeys.coupon, { selected: newSelected });
  }, [cartValue]);

  return (
    <CouponContext.Provider value={{ list, selected, select, setList: setListAndPersist }}>
      {children}
    </CouponContext.Provider>
  );
};

export const useCouponContext = () => {
  const context = useContext<CouponContextType>(CouponContext);
  if (!context) {
    throw new Error(
      'useCouponContext must be used within an CouponContextProvider'
    );
  }
  return context;
};

export const useRefreshCoupons = () => {
  const { data, mutate, isLoading, error } = useFetchCoupons();
  const { enqueue } = useAlert();
  const { setList } = useCouponContext();

  useEffect(() => {
    if (data) {
      setList(data);
    }
  }, [data, setList]);

  useEffect(() => {
    if (error) {
      enqueue(CouponAlertStates.RETRIEVE_COUPON_ERROR);
    }
  }, [error, enqueue]);

  return {
    refresh: mutate,
    data,
    isLoading
  };
}
