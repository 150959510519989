import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useMemo } from 'react';

import HorizontalRadio, { HorizontalRadioProps } from '../../component/HorizontalRadioProps';
import useAlert from '../../hooks/useAlert';
import { useCartValue } from '../../state/cart';
import { useCouponContext, useRefreshCoupons } from '../../state/coupon';
import { CouponAlertStates } from '../../utils/alert';
import { realToCents } from '../../utils/conversion';
import { validateCoupon } from '../../utils/coupon';

const CouponsSection: React.FC = () => {
  const coupon = useCouponContext();
  const { enqueue } = useAlert();
  const { isLoading } = useRefreshCoupons();
  const { select } = useCouponContext();
  const cartValue = useCartValue();

  // when comes to screen reset selected coupon
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { select(undefined); }, []);

  const coupons = useMemo<HorizontalRadioProps[]>(() => [
    {
      image: LocalActivityIcon,
      title: 'Sem Cupom',
      checked: coupon.selected === undefined,
      onClick: () => select(undefined)
    },
    ...(isLoading ?
      Array<HorizontalRadioProps>() :
      coupon.list.items.map((c) => ({
        image: LocalActivityIcon,
        title: c.description,
        checked: coupon.selected && coupon.selected.id === c.id,
        onClick: () => {
          if (!validateCoupon(c, realToCents(cartValue))) {
            return enqueue(CouponAlertStates.UNSELECTED_COUPON_ERROR)
          }
          select(c);
        }
      })))
  ], [coupon, cartValue, enqueue, isLoading, select]);

  return <>
    {coupons.map((c) => (
      <HorizontalRadio key={c.title} {...c} />
    ))}
    {isLoading && <Skeleton sx={style.skeleton} animation='wave' variant="rectangular" />}
  </>
};

const style = {
  skeleton: {
    margin: '4px 0 0 0',
    height: '64px',
  },
};

export default React.memo(CouponsSection);
