import { useMemo } from 'react';

import useFetch from '../hooks/useFetch';
import { AMLabsProduct } from '../model/product';

const ep = '/products';

export const useFetchProductsByPlanogramId = (planogramId: string) => {
  // planogramId is needed to fetch products
  const url = useMemo(() => planogramId ? ep : null, [planogramId]);
  return useFetch<AMLabsProduct[]>(url, { available: true, planogramId });
}
