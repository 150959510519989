import { CircularProgress, Link } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFetchWalletCards } from '../../api/wallet';
import HorizontalRadio from '../../component/HorizontalRadioProps';
import WalletCard from '../../component/WalletCard';
import { IWalletCard } from '../../model/wallet';
import { ScreenRoutes } from '../../router';
import { globalStyles } from '../../theme';

interface Props {
  onCardSelect: (card: IWalletCard) => void;
  selectedCard?: IWalletCard;
}

const CardReview: React.FC<Props> = ({
  onCardSelect, selectedCard
}: Props) => {
  const { data: cards, isLoading } = useFetchWalletCards();
  const navigate = useNavigate();
  const handleProfileLink = useCallback(() => {
    navigate(ScreenRoutes.PROFILE);
  }, [navigate]);

  if (isLoading) {
    return (
      <Box marginY={2} textAlign='center'>
        <CircularProgress size={globalStyles.circularProgress.size} />
      </Box>
    );
  }

  if (!isLoading && cards && cards.length === 0) {
    return (
      <Box marginY={2} textAlign='center'>
        <p>
          Você não possui cartões cadastrados. Acesse a aba{' '}
          <Link onClick={handleProfileLink}>perfil</Link>
          {' '}para criar o seu
        </p>
      </Box>
    );
  }

  return (
    <Box sx={style.container}>
      {cards && (
        cards.map((card) => {
          return (
            <HorizontalRadio
              key={card.number}
              title=''
              checked={selectedCard?.number === card.number}
              onClick={() => onCardSelect(card)}
            >
              <WalletCard minimalist card={card} key={card.number}></WalletCard>
            </HorizontalRadio>
          );
        })
      )}
    </Box>
  );
};

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '5px',
    width: '100%',
    padding: '0',
    margin: 'auto',
    overflow: 'auto',
    '> div': {
      backgroundColor: 'transparent !important',
    }
  },
};

export default React.memo(CardReview);
