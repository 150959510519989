import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';

import TextField from '../../component/TextField';
import { Mask } from '../../mask';
import CellphoneMask from '../../mask/CellphoneMask';
import DateMask from '../../mask/DateMask';
import { UpdateUserRequest, UserGender } from '../../model/user';
import { useUpdateUser, useUserContext } from '../../state/user';
import { globalStyles } from '../../theme';
import syntaxValidator from '../../utils/validator';

interface BasicInformationFormData {
  gender: { value: UserGender | ''; error: boolean };
  lastName: { value: string; error: boolean };
  name: { value: string; error: boolean };
  cellphone: { value: string; error: boolean };
  bornDate: { value: string; error: boolean };
}

const BasicInformation: React.FC = () => {
  const { user } = useUserContext();
  const [formData, setFormData] = useState<BasicInformationFormData>({
    name: { value: user.name ?? '', error: false },
    lastName: { value: user.lastName ?? '', error: false },
    bornDate: { value: user.bornDate ?? '', error: false },
    gender: { value: user.gender ?? '', error: false },
    cellphone: { value: user.cellphone ?? '', error: false },
  });
  const { update: updateUser, isLoading } = useUpdateUser();

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: { value, error: false },
    }));
  };

  const handleErrorChange = useCallback((key: keyof BasicInformationFormData, error: boolean) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: { ...prevFormData[key], error },
    }));
  }, [setFormData]);

  const handleUpdateProfile = useCallback(async () => {
    let valid = true;
    if (!syntaxValidator.naming(formData.name.value)) {
      handleErrorChange('name', true);
      valid = false;
    }
    // not required
    if (!syntaxValidator.nonempty(formData.lastName.value) && formData.lastName.value !== '') {
      handleErrorChange('lastName', true);
      valid = false;
    }
    // not required
    if (!syntaxValidator.cellphone(formData.cellphone.value) && formData.cellphone.value !== '') {
      handleErrorChange('cellphone', true);
      valid = false;
    }
    if (!syntaxValidator.bornDate(formData.bornDate.value)) {
      handleErrorChange('bornDate', true);
      valid = false;
    }

    if (!valid) {
      return;
    }

    const updatedUser: UpdateUserRequest = {
      name: formData.name.value,
      lastName: formData.lastName.value,
      bornDate: formData.bornDate.value,
      gender: formData.gender.value !== '' ? formData.gender.value : undefined,
      cellphone: formData.cellphone.value,
    };

    await updateUser(updatedUser);
  }, [formData, handleErrorChange, updateUser]);

  return (
    <>
      <Typography variant="h6" sx={globalStyles.sectionTitle}>
        Informações Básicas
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Nome"
            type="text"
            value={formData.name.value}
            onChange={handleValueChange}
            name='name'
            InputLabelProps={{ shrink: true }}
            error={formData.name.error}
            helperText={formData.name.error ? 'Nome inválido' : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Sobrenome"
            type="text"
            value={formData.lastName.value}
            onChange={handleValueChange}
            name='lastName'
            InputLabelProps={{ shrink: true }}
            error={formData.lastName.error}
            helperText={formData.lastName.error ? 'Sobrenome inválido' : ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Data de nascimento"
            type="text"
            name="bornDate"
            value={formData.bornDate.value}
            onChange={handleValueChange}
            InputProps={{
              inputComponent: DateMask as Mask,
              inputMode: 'numeric'
            }}
            InputLabelProps={{ shrink: true }}
            error={formData.bornDate.error}
            helperText={formData.bornDate.error ? 'Data inválida' : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Celular"
            type="text"
            name="cellphone"
            value={formData.cellphone.value}
            onChange={handleValueChange}
            InputProps={{
              inputComponent: CellphoneMask as Mask,
              inputMode: 'numeric'
            }}
            InputLabelProps={{ shrink: true }}
            error={formData.cellphone.error}
            helperText={formData.cellphone.error ? 'Celular inválido' : ''}
          />
        </Grid>
      </Grid>
      <Box sx={globalStyles.customInputContainer}>
        <InputLabel sx={globalStyles.shrinkedLabelInput}>
          Gênero
        </InputLabel>
        <RadioGroup
          row
          value={formData.gender.value}
          onChange={handleValueChange}
          name='gender'
        >
          <FormControlLabel value="female" control={<Radio size='small' />} label="Feminino" />
          <FormControlLabel value="male" control={<Radio size='small' />} label="Masculino" />
        </RadioGroup>
      </Box>
      <TextField
        label="CPF"
        type="text"
        value={user.taxId}
        name='taxId'
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Email"
        type="email"
        value={user.email}
        name='email'
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <Button
        variant="contained"
        fullWidth
        disableElevation
        sx={[globalStyles.radiusButton, style.button]}
        onClick={handleUpdateProfile}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={globalStyles.circularProgress.size} /> : 'Atualizar Perfil'}
      </Button>
    </>
  );
};

const style = {
  button: {
    marginTop: '16px',
  }
}

export default React.memo(BasicInformation);
