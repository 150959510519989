import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateWalletCardMutation, useFetchWalletCards } from '../../api/wallet';
import TextField from '../../component/TextField';
import WalletCard from '../../component/WalletCard';
import useAlert from '../../hooks/useAlert';
import { Mask } from '../../mask';
import CardNumberMask from '../../mask/CardNumberMask';
import CpfTaxIdMask from '../../mask/CpfTaxIdMask';
import { IWalletCard, WalletCardCreate } from '../../model/wallet';
import { ScreenRoutes } from '../../router';
import { globalStyles } from '../../theme';
import { CardAlertStates } from '../../utils/alert';
import syntaxValidator from '../../utils/validator';

export interface WalletCardForm {
  number: { value: string; error: boolean };
  expMonth: { value: string; error: boolean };
  expYear: { value: string; error: boolean };
  holder: { value: string; error: boolean };
  taxId: { value: string; error: boolean };
}

const WalletInformation: React.FC = () => {
  const navigate = useNavigate();
  const { enqueue } = useAlert();
  const [formData, setFormData] = useState<WalletCardForm>({
    number: { value: '' ?? '', error: false },
    expMonth: { value: '' ?? '', error: false },
    expYear: { value: '' ?? '', error: false },
    holder: { value: '' ?? '', error: false },
    taxId: { value: '' ?? '', error: false },
  });
  const { trigger: createCard, isLoading: creationLoading } = useCreateWalletCardMutation();

  const handleWalletCardClick = useCallback((card: IWalletCard) => {
    navigate(ScreenRoutes.WALLET, { state: { card } });
  }, [navigate]);

  const [create, setCreate] = useState(false);

  const { data: cards, isLoading, mutate } = useFetchWalletCards();

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: { value, error: false },
    }));
  };

  const handleErrorChange = useCallback((key: keyof WalletCardCreate, error: boolean) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: { ...prevFormData[key], error },
    }));
  }, [setFormData]);

  const reset = useCallback(() => {
    setFormData({
      number: { value: '', error: false },
      expMonth: { value: '', error: false },
      expYear: { value: '', error: false },
      taxId: { value: '', error: false },
      holder: { value: '', error: false },
    });
  }, []);

  const handleOnClose = useCallback(() => {
    setCreate(false);
    reset();
  }, [reset]);

  const handleCreateCard = useCallback(async () => {
    let valid = true;

    if (!syntaxValidator.cardNumber(formData.number.value)) {
      handleErrorChange('number', true);
      valid = false;
    }

    if (!syntaxValidator.cardExpMonth(formData.expMonth.value)) {
      handleErrorChange('expMonth', true);
      valid = false;
    }

    if (!syntaxValidator.cardExpYear(formData.expYear.value)) {
      handleErrorChange('expYear', true);
      valid = false;
    }

    if (!syntaxValidator.nonempty(formData.holder.value)) {
      handleErrorChange('holder', true);
      valid = false;
    }

    if (!syntaxValidator.cpfTaxId(formData.taxId.value)) {
      handleErrorChange('taxId', true);
      valid = false;
    }

    if (!valid) {
      return;
    }

    try {
      await createCard({
        number: formData.number.value,
        expMonth: parseInt(formData.expMonth.value),
        expYear: parseInt(formData.expYear.value),
        holder: formData.holder.value,
        taxId: formData.taxId.value,
      });
      await mutate();
      enqueue(CardAlertStates.CREATE_SUCCESS);
      setCreate(false);
    } catch (error) {
      enqueue(CardAlertStates.CREATE_ERROR);
    } finally {
      reset();
    }
  }, [
    formData.number.value,
    formData.expMonth.value,
    formData.expYear.value,
    formData.holder.value,
    formData.taxId.value,
    handleErrorChange,
    createCard,
    mutate,
    enqueue,
    reset
  ]);

  return (
    <Box sx={style.container}>
      <Typography variant="h6" sx={globalStyles.sectionTitle}>
        Cartões Cadastrados
      </Typography>

      {isLoading && <Box textAlign={'center'}>
        <CircularProgress size={globalStyles.circularProgress.size} />
      </Box>}
      {cards && cards.length === 0 && (
        <Alert severity="info">Você ainda não possui cartões cadastrados</Alert>
      )}
      {cards && (
        cards.map((card) => {
          return (
            <WalletCard onClick={() => handleWalletCardClick(card)} card={card} key={card.number}></WalletCard>
          );
        })
      )}
      {!create && (
        <div>
          <Button
            variant="contained"
            fullWidth
            disableElevation
            sx={[globalStyles.radiusButton, style.button]}
            onClick={() => setCreate(true)}
          >
            {<CircularProgress size={globalStyles.circularProgress.size} /> && 'Adicionar Cartão de Crédito'}
          </Button>
        </div>
      )}
      {create && (
        <>
          <Box sx={style.formContainer}>
            <TextField
              label="Nome do titular"
              type="text"
              name={'holder'}
              value={formData.holder.value}
              onChange={handleValueChange}
              error={formData.holder.error}
              helperText={formData.number.error ? 'Nome do titular inválido' : ''}
              InputLabelProps={{ shrink: true }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Número do cartão"
                  type="text"
                  name='number'
                  value={formData.number.value}
                  onChange={handleValueChange}
                  InputProps={{
                    inputComponent: CardNumberMask as Mask,
                    inputMode: 'text'
                  }}
                  error={formData.number.error}
                  helperText={formData.number.error ? 'Número do cartão' : ''}
                  InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="CPF do portador"
                  type="text"
                  onChange={handleValueChange}
                  name='taxId'
                  value={formData.taxId.value}
                  error={formData.taxId.error}
                  helperText={formData.taxId.error ? 'CPF inválido' : ''}
                  InputProps={{
                    inputComponent: CpfTaxIdMask as Mask,
                    inputMode: 'numeric'
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  label="Mês de expiração"
                  onChange={handleValueChange}
                  value={formData.expMonth.value}
                  error={formData.expMonth.error}
                  name='expMonth'
                  helperText={formData.number.error ? 'Mês de expiração inválido' : ''}
                  InputProps={{
                    inputMode: 'numeric'
                  }}
                  InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  label="Ano de expiração"
                  onChange={handleValueChange}
                  value={formData.expYear.value}
                  error={formData.expYear.error}
                  name='expYear'
                  helperText={formData.number.error ? 'Ano de expiração inválido' : ''}
                  InputProps={{
                    inputMode: 'numeric'
                  }}
                  InputLabelProps={{ shrink: true }} />
              </Grid>

            </Grid>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Box>
                <Button
                  variant="text"
                  sx={[globalStyles.radiusButton, style.button]}
                  onClick={handleOnClose}
                >
                  Cancelar
                </Button>
              </Box>
              <Box sx={style.addButtonContainer}>
                <Button
                  variant="contained"
                  disableElevation
                  sx={[globalStyles.radiusButton, style.button]}
                  onClick={handleCreateCard}
                  disabled={creationLoading}
                  fullWidth
                >
                  {creationLoading ?
                    <CircularProgress size={globalStyles.circularProgress.size} /> :
                    'Salvar cartão de crédito'}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const style = {
  addButtonContainer: {
    width: '100%',
    marginLeft: '32px',
  },
  button: {
    marginTop: '16px',
  },
  formContainer: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  container: {
    marginBottom: '16px',
  }
}

export default React.memo(WalletInformation);