import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useFetchPointOfSales } from '../api/pos';
import { useFetchProductsByPlanogramId } from '../api/products';
import useAlert from '../hooks/useAlert';
import { PointOfSale } from '../model/pos';
import { AMLabsProduct } from '../model/product';
import { PosAlertStates, ProductAlertStates } from '../utils/alert';
import { List } from '../utils/data';

import { useLoadingContext } from './loading';
import { persist, persistKeys } from './persistor';


export interface PosContextType extends PosPersist {
  setInventory: (newInventory: AMLabsProduct[]) => void;
  resetInventory: () => void;
  select: (newSelected: PointOfSale) => void;
  setList: (newList: List<PointOfSale>) => void;
}

export const defaultPosState: PosContextType = {
  selected: undefined,
  inventory: [],
  list: { items: [], count: 0 },
  setInventory: () => { },
  resetInventory: () => { },
  select: () => { },
  setList: () => { },
};

interface PosPersist {
  selected?: PointOfSale;
  inventory: AMLabsProduct[];
  list: List<PointOfSale>;
}

export const PosContext = createContext<PosContextType>(defaultPosState);

export const PosProvider = ({ children }: { children: React.ReactNode }) => {
  const [selected, setSelected] = useState<PointOfSale | undefined>(undefined);
  const [inventory, setInventory] = useState<AMLabsProduct[]>([]);
  const [list, setList] = useState<List<PointOfSale>>(defaultPosState.list);

  const setInventoryAndPersist = useCallback((newInventory: AMLabsProduct[]) => {
    setInventory(newInventory);
    persist<PosPersist>(persistKeys.pos, { inventory: newInventory });
  }, []);

  const resetInventory = useCallback(() => { setInventoryAndPersist([]); }, [setInventoryAndPersist]);

  const select = useCallback((newSelected: PointOfSale) => {
    setSelected(newSelected);
    persist<PosPersist>(persistKeys.pos, { selected: newSelected });
  }, []);

  const setListAndPersist = useCallback((newList: List<PointOfSale>) => {
    setList(newList);
    persist<PosPersist>(persistKeys.pos, { list: newList });
  }, []);

  useEffect(() => {
    const posState: PosPersist = JSON.parse(localStorage.getItem(persistKeys.pos) ?? '{}');
    if (posState) {
      setSelected(posState.selected ?? defaultPosState.selected);
      setInventory(posState.inventory ?? defaultPosState.inventory);
      setList(posState.list ?? defaultPosState.list);
    }
  }, []);


  return (
    <PosContext.Provider value={{
      selected,
      inventory,
      list,
      setInventory: setInventoryAndPersist,
      resetInventory,
      select,
      setList: setListAndPersist,
    }}>
      {children}
    </PosContext.Provider>
  );
};

export const usePosContext = () => {
  const context = useContext<PosContextType>(PosContext);
  if (!context) {
    throw new Error(
      'usePosContext must be used within a PosContextProvider'
    );
  }
  return context;
};


export const useRefreshPos = () => {
  const { selected, setList, select } = usePosContext();
  const { add, del } = useLoadingContext();
  const { enqueue } = useAlert();
  const { data, mutate } = useFetchPointOfSales();

  useEffect(() => {
    if (data) {
      setList(data);
    }
    const hasSelected = selected && data?.items.find((pos) => pos.id === selected.id);

    if (!hasSelected && data) {
      select(data.items[0]);
    }
  }, [data, select, selected, setList]);

  const refreshPos = useCallback(async () => {
    try {
      add();
      await mutate();
    } catch (error) {
      enqueue(PosAlertStates.RETRIEVE_POS_ERROR);
    } finally {
      del();
    }
  }, [add, del, enqueue, mutate]);

  return {
    refresh: refreshPos,
  };
}

export const useRefreshInventory = () => {
  const { selected, setInventory } = usePosContext();
  const { enqueue } = useAlert();
  const { data, isLoading, error } = useFetchProductsByPlanogramId(selected?.planogramId ?? '');

  useEffect(() => {
    if (isLoading) {
      // simulate loading
      return setInventory([]);
    }
    if (!data) return;
    setInventory(data);
  }, [data, setInventory, isLoading]);

  useEffect(() => {
    if (!error) return;
    enqueue(ProductAlertStates.PRODUCT_ERROR);
  }, [enqueue, error]);
}
